import React, { JSX, useEffect, useState } from 'react';

import { SupportedModule } from '@apus/common-lib/integrations/src/interface';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import ErrorOutlineTwoToneIcon from '@mui/icons-material/ErrorOutlineTwoTone';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import useTenant from '@apus/common-ui/hooks/useTenant';
import useIntegrationService from '@apus/common-ui/hooks/useIntegrationService';
import { executeApiCall } from '@apus/common-ui/utils/api-call';
import OAuthAuthorizationCodeHandler from '../../components/oauth/OAuthAuthorizationCodeHandler';

function connectionDetails(moduleId: SupportedModule): {
  name: string;
  description?: string;
} {
  switch (moduleId) {
    case 'pagero':
      return {
        name: 'Pagero API',
      };
    case 'microsoft-graph':
      return { name: 'Microsoft Graph API' };
    case 'nordea-business':
      return {
        name: 'Nordea Business API',
      };
    case 'rabobank':
      return {
        name: 'Rabobank API',
      };
    case 'clickup':
      return {
        name: 'ClickUp API',
      };
    case 'qonto':
      return {
        name: 'Qonto API',
      };
  }

  return {
    name: moduleId,
  };
}

const Connection = ({
  moduleConfiguration,
}: {
  moduleConfiguration: ModuleConfiguration;
}): JSX.Element => {
  const tenant = useTenant();

  const { name, description } = connectionDetails(moduleConfiguration.moduleId);

  if (tenant === undefined) return <div>No tenant defined</div>;

  return (
    <Grid item margin={2} key={moduleConfiguration.moduleId}>
      <Box
        sx={{
          minHeight: 100,
          width: 400,
          backgroundColor: 'lavender',
          border: 1,
          textAlign: 'center',
          borderRadius: 3,
          boxShadow: 3,
          padding: 1,
        }}
      >
        <Typography variant={'h6'} margin={1}>
          {name}
        </Typography>
        {description !== undefined && (
          <Typography variant={'subtitle2'} margin={1} align={'left'}>
            {description}
          </Typography>
        )}
        <Box margin={1}>
          {moduleConfiguration.enabled ? (
            <Typography
              display={'flex'}
              alignContent={'center'}
              justifyContent={'center'}
            >
              <CheckCircleOutlineTwoToneIcon color={'success'} />
              Enabled
            </Typography>
          ) : (
            <Typography>
              <ErrorOutlineTwoToneIcon color={'error'} />
              Not enabled
            </Typography>
          )}
        </Box>

        {moduleConfiguration.actionsNeeded?.map((action, idx) => {
          if (action === 'tenant-authorization') {
            return (
              <OAuthAuthorizationCodeHandler
                key={`oauth-handler-${idx}`}
                connection={moduleConfiguration.moduleId}
                tenantId={tenant?.tenantId}
              />
            );
          }
        })}
      </Box>
    </Grid>
  );
};

const TenantConnectionsView = (): JSX.Element => {
  const integrationService = useIntegrationService();
  const [, setIsLoading] = useState<boolean>(false);
  const [connections, setConnections] = useState<ModuleConfiguration[]>([]);

  useEffect(() => {
    (async () => {
      await executeApiCall({
        callFunction: () => integrationService.listModuleConfigurations(),
        setResult: setConnections,
        setPending: setIsLoading,
      });
    })();
  }, [integrationService, setConnections, setIsLoading]);

  return (
    <Grid container>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {connections.map((moduleConfiguration, idx) => {
          return (
            <Connection key={idx} moduleConfiguration={moduleConfiguration} />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default TenantConnectionsView;
