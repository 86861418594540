import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { ModuleConfigurationFormProps } from './interface';
import { ServiceProviderRabobankApiConfiguration } from '@apus/common-lib/integrations/src/rabobank/interface';

const ServiceProviderPageroConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form = useForm<ServiceProviderRabobankApiConfiguration>();

  useEffect(() => {
    if (moduleConfiguration !== undefined) {
      const configuration =
        moduleConfiguration.configuration as unknown as ServiceProviderRabobankApiConfiguration;
      form.reset(configuration);
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (
    formData: ServiceProviderRabobankApiConfiguration
  ) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId: moduleId,
      configuration: formData as unknown as SourceObject,
    };

    onSave(saved);
  };

  return (
    <Grid container spacing={2}>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Rabobank Business API -connection for service provider
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <HookFormTextInput
            name="baseUrl"
            form={form}
            label="Base URL to Rabobank API"
            helperText="E.g. https://api-sandbox.rabobank.nl"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="authorizationServerEndpoint"
            form={form}
            label="Authorization server endpoint"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="tokenServerEndpoint"
            form={form}
            label="Token server endpoint"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientId"
            form={form}
            label="Client id provided by Rabobank"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="clientSecret"
            form={form}
            label="Client secret provided by Rabobank"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="mtlsKey"
            form={form}
            label="Mutual TLS private key"
            defaultValue={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
          <HookFormTextInput
            name="mtlsCertificate"
            form={form}
            label="Mutual TLS certificate"
            defaultValue={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
          <HookFormTextInput
            name="signingCertificateSerialNumber"
            form={form}
            label="Signing certificate id"
            defaultValue={''}
            disabled={!edit}
          />
          <HookFormTextInput
            name="signingKey"
            form={form}
            label="Signing private key"
            defaultValue={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
          <HookFormTextInput
            name="signingCertificate"
            form={form}
            label="Signing certificate"
            defaultValue={''}
            multiline={true}
            minRows={10}
            disabled={!edit}
          />
        </Stack>
      </Grid>
      <Grid item>
        <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default ServiceProviderPageroConfigurationForm;
