export const unknownAppSubscription = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    app: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          enum: [
            'systemglue-integration-app',
            'payment-integration-app',
            'bank-transaction-integration-app',
          ],
        },
        configuration: {},
      },
      required: ['name', 'configuration'],
    },
    status: {
      type: 'string',
      enum: [
        'Created',
        'Configuring',
        'Configured',
        'ConfigurationFailed',
        'Active',
        'Cancelling',
        'Cancelled',
        'Deleted',
      ],
      title: 'Internally used status enum for subscriptions',
    },
    subscriptionDetails: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          description: 'User-definable name for the integration',
        },
        disabled: { type: 'boolean' },
        startDate: { type: 'string' },
        endDate: { type: 'string' },
        createdDate: { type: 'string' },
        updatedDate: { type: 'string' },
      },
      required: ['startDate', 'createdDate', 'updatedDate'],
    },
  },
  required: ['id', 'app', 'status', 'subscriptionDetails'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const paymentIntegrationAppSubscription = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    app: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          const: 'payment-integration-app',
          title: 'Application name',
        },
        configuration: {
          type: 'object',
          properties: {
            connections: {
              type: 'object',
              properties: {
                erp: { type: 'string', const: 'netsuite', title: 'Used ERP' },
                bank: {
                  type: 'string',
                  const: 'osuuspankki',
                  title: 'Used bank',
                },
              },
              required: ['erp', 'bank'],
              title: 'System connections',
            },
            subsidiary: {
              type: 'object',
              properties: {
                subsidiaryId: {
                  type: 'string',
                  title: 'Identifier for the subsidiary',
                },
              },
              required: ['subsidiaryId'],
              title: 'ERP subsidiary details',
            },
            bank: {
              type: 'object',
              properties: {
                name: { type: 'string', title: 'Bank name' },
                addressLine1: {
                  type: 'string',
                  title: 'Street address line 1',
                },
                addressLine2: {
                  type: 'string',
                  title: 'Street address line 2',
                },
                countryCode: {
                  type: 'string',
                  title: 'Country code in ISO-3166 2-letters',
                },
              },
              required: ['name', 'addressLine1', 'countryCode'],
              title: 'Bank details',
            },
            account: {
              type: 'object',
              properties: {
                iban: {
                  type: 'string',
                  title: "Subsidiary's bank account number in IBAN -format",
                  description:
                    'This is used to match incoming payments to configurations',
                },
                bic: {
                  type: 'string',
                  title: "Subsidiary's bank identification code in BIC -format",
                },
                routingNumber: {
                  type: 'string',
                  title: 'The unique identification id for the bank',
                },
                organisationIdentification: {
                  type: 'string',
                  title: 'The unique service number issued by the bank',
                },
              },
              required: ['iban', 'bic'],
              title: 'Bank account details',
            },
          },
          required: ['connections', 'subsidiary', 'bank', 'account'],
        },
      },
      required: ['configuration', 'name'],
    },
    status: {
      type: 'string',
      enum: [
        'Created',
        'Configuring',
        'Configured',
        'ConfigurationFailed',
        'Active',
        'Cancelling',
        'Cancelled',
        'Deleted',
      ],
      title: 'Internally used status enum for subscriptions',
    },
    subscriptionDetails: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          description: 'User-definable name for the integration',
        },
        disabled: { type: 'boolean' },
        startDate: { type: 'string' },
        endDate: { type: 'string' },
        createdDate: { type: 'string' },
        updatedDate: { type: 'string' },
      },
      required: ['startDate', 'createdDate', 'updatedDate'],
    },
  },
  required: ['id', 'app', 'status', 'subscriptionDetails'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const systemglueIntegrationAppSubscription = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    app: {
      type: 'object',
      properties: {
        name: { type: 'string', const: 'systemglue-integration-app' },
        configuration: {
          type: 'object',
          properties: {
            monthlyActions: {
              type: 'object',
              properties: {
                serviceLevel: { type: 'string', enum: ['Basic', 'Custom'] },
                customValue: { type: 'number' },
              },
              required: ['serviceLevel'],
            },
            actionStorageDuration: {
              type: 'object',
              properties: {
                serviceLevel: { type: 'string', enum: ['Basic', 'Custom'] },
                customValue: { type: 'number' },
              },
              required: ['serviceLevel'],
            },
          },
          required: ['monthlyActions', 'actionStorageDuration'],
        },
      },
      required: ['configuration', 'name'],
    },
    status: {
      type: 'string',
      enum: [
        'Created',
        'Configuring',
        'Configured',
        'ConfigurationFailed',
        'Active',
        'Cancelling',
        'Cancelled',
        'Deleted',
      ],
      title: 'Internally used status enum for subscriptions',
    },
    subscriptionDetails: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          description: 'User-definable name for the integration',
        },
        disabled: { type: 'boolean' },
        startDate: { type: 'string' },
        endDate: { type: 'string' },
        createdDate: { type: 'string' },
        updatedDate: { type: 'string' },
      },
      required: ['startDate', 'createdDate', 'updatedDate'],
    },
  },
  required: ['id', 'app', 'status', 'subscriptionDetails'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};

export const bankTransactionIntegrationAppSubscription = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    app: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          const: 'bank-transaction-integration-app',
          title: 'Application name',
        },
        configuration: {
          type: 'object',
          properties: {
            connections: {
              type: 'object',
              properties: {
                erp: { type: 'string', const: 'netsuite', title: 'Used ERP' },
                bank: {
                  type: 'string',
                  const: 'osuuspankki',
                  title: 'Used bank',
                },
              },
              required: ['erp', 'bank'],
              title: 'System connections',
            },
            account: {
              type: 'object',
              properties: {
                iban: {
                  type: 'string',
                  title: "Subsidiary's bank account number in IBAN -format",
                  description:
                    'This is used to match incoming payments to configurations',
                },
                bic: {
                  type: 'string',
                  title: "Subsidiary's bank identification code in BIC -format",
                },
              },
              required: ['iban', 'bic'],
              title: 'Bank account details',
            },
            transactions: {
              type: 'object',
              properties: {
                referenceFieldName: { type: 'string' },
                referenceFieldName2: { type: 'string' },
                referenceFieldName3: { type: 'string' },
                referenceFieldName4: { type: 'string' },
                referenceFieldName5: { type: 'string' },
                numberFieldName: { type: 'string' },
                numberFieldName2: { type: 'string' },
                numberFieldName3: { type: 'string' },
                numberFieldName4: { type: 'string' },
                numberFieldName5: { type: 'string' },
              },
              required: ['referenceFieldName', 'numberFieldName'],
            },
            general: {
              type: 'object',
              properties: {
                dateFormat: { type: 'string' },
                roundingTolerance: { type: 'number' },
              },
              required: ['dateFormat'],
            },
            rules: {
              type: 'object',
              properties: {
                bankTransactionHandling: {
                  type: 'array',
                  items: {
                    type: 'object',
                    properties: {
                      name: {
                        type: 'string',
                        description: 'Human-readable name for the rule',
                      },
                      description: {
                        type: 'string',
                        description: 'Human-readable description for the rule',
                      },
                      ruleId: {
                        type: 'string',
                        description: 'Internal id (UUID) for the rule',
                      },
                      condition: {
                        type: 'string',
                        description:
                          'Configure Json Logic rule as the condition',
                      },
                      isDefault: {
                        type: 'boolean',
                        description:
                          'Is this the default rule\n\nDefault rules can be overridden by making a customised version of the use case',
                      },
                      ruleGroup: {
                        type: 'string',
                        const: 'bank-transaction-handling-rule',
                        description: 'Rule type',
                      },
                      configuration: { description: 'Rule configuration' },
                      handler: {
                        type: 'string',
                        enum: [
                          'generate-customer-payments-per-identified-transaction',
                          'generate-customer-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                          'generate-vendor-payments-per-identified-transaction',
                          'generate-vendor-payments-per-identified-transaction-and-a-journal-entry-of-difference',
                          'generate-consolidated-customer-payments-per-identified-transaction',
                        ],
                      },
                      disableAutomatedHandling: { type: 'boolean' },
                    },
                    required: [
                      'condition',
                      'handler',
                      'name',
                      'ruleGroup',
                      'ruleId',
                    ],
                  },
                },
              },
              required: ['bankTransactionHandling'],
            },
          },
          required: ['connections', 'account', 'transactions', 'general'],
        },
      },
      required: ['configuration', 'name'],
    },
    status: {
      type: 'string',
      enum: [
        'Created',
        'Configuring',
        'Configured',
        'ConfigurationFailed',
        'Active',
        'Cancelling',
        'Cancelled',
        'Deleted',
      ],
      title: 'Internally used status enum for subscriptions',
    },
    subscriptionDetails: {
      type: 'object',
      properties: {
        name: {
          type: 'string',
          description: 'User-definable name for the integration',
        },
        disabled: { type: 'boolean' },
        startDate: { type: 'string' },
        endDate: { type: 'string' },
        createdDate: { type: 'string' },
        updatedDate: { type: 'string' },
      },
      required: ['startDate', 'createdDate', 'updatedDate'],
    },
  },
  required: ['id', 'app', 'status', 'subscriptionDetails'],
  $schema: 'http://json-schema.org/draft-07/schema#',
};
