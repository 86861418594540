import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { ModuleConfiguration } from '@apus/common-lib/api/interface/integration-service';
import { SourceObject } from '@apus/common-lib/json-data-mapper/src/interface';
import HookFormTextInput from '@apus/common-ui/components/hook-form/HookFormTextInput';
import { Stack, Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';

import {
  ModuleConfigurationEdit,
  ModuleConfigurationFormProps,
} from './interface';
import { TenantNordeaBusinessApiConfiguration } from '@apus/common-lib/integrations/src/nordea-business/interface';
import ConfigureModuleActions from '../ConfigureModuleActions';

const TenantPageroConfigurationForm = ({
  moduleId,
  moduleConfiguration,
  onSave,
  edit,
  hideTitle,
  titleProps = { variant: 'h5' },
}: ModuleConfigurationFormProps): JSX.Element => {
  const form =
    useForm<ModuleConfigurationEdit<TenantNordeaBusinessApiConfiguration>>();

  useEffect(() => {
    if (moduleConfiguration !== undefined) {
      const configuration =
        moduleConfiguration.configuration as unknown as TenantNordeaBusinessApiConfiguration;

      form.reset({
        actionsNeeded: moduleConfiguration.actionsNeeded,
        configuration,
      });
    }
  }, [form, moduleConfiguration]);

  const onSubmit = async (
    formData: ModuleConfigurationEdit<TenantNordeaBusinessApiConfiguration>
  ) => {
    const saved: ModuleConfiguration = {
      ...moduleConfiguration,
      moduleId: moduleId,
      actionsNeeded: formData.actionsNeeded,
      configuration: formData.configuration as unknown as SourceObject,
    };
    onSave(saved);
  };

  return (
    <Grid container spacing={2}>
      {hideTitle !== true && (
        <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography {...titleProps}>
            Configure Rabobank API-connection for tenant
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Controller
            control={form.control}
            name={'actionsNeeded'}
            render={({ field }) => {
              return (
                <ConfigureModuleActions
                  actions={['tenant-authorization']}
                  onChange={field.onChange}
                />
              );
            }}
          />
          <HookFormTextInput
            name="configuration.scope"
            form={form}
            label="Agreed scopes separated by a comma"
            helperText="For example bspi.single.read-write, bai.accountinformation.read"
          />
          <HookFormTextInput
            name="configuration.authorizationDate"
            form={form}
            label="Authorization date"
            disabled={true}
          />
        </Stack>
      </Grid>
      <Grid item>
        <Button disabled={!edit} onClick={form.handleSubmit(onSubmit)}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default TenantPageroConfigurationForm;
